<div
  *ngIf="!isFullPageLoading"
  class="container sm:mx-auto sm:ps-10 pt-6 bg-white flex flex-col mb-10"
>
  <div class="cursor-pointer mb-6">
    <span routerLink="/admin/refund">Refund Approval</span>
    <span> &nbsp; > &nbsp;</span>
    <span class="font-bold">
      request {{ participantDetail._id | changeCardNumber }}
    </span>
  </div>
  <h1 class="text-2xl font-semibold mb-5">
    Request {{ participantDetail._id | changeCardNumber }}
  </h1>
  <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-4">
    <div class="grid grid-cols-1 md:grid-cols-2 max-md:gap-10">
      <div class="flex flex-col gap-10">
        <div class="flex items-center gap-2">
          <div
            class="w-10 h-10 rounded-full border-gray-800 border-spacing- text-sm"
          >
            <img
              class="rounded-full object-fill"
              [src]="participantDetail.events.userId.pictureUrl"
              alt="profile picture"
            />
          </div>
          <span class="sm:text-sm font-semibold">{{
            participantDetail.events.userId.name
          }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Event Booking</h6>
          <p class="font-normal">
            {{ participantDetail.events.eventName }}
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-7">
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Booking time</h6>
          <p class="font-normal">
            {{ participantDetail.events.paymentedAt | date : "dd/MM/yyy" }};
            {{ participantDetail.events.paymentedAt | date : "hh:mm" }}
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Event time</h6>
          <p class="font-normal">
            {{ participantDetail.events.date }};
            {{ participantDetail.events.time }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-4">
    <h1 class="text-2xl font-semibold mb-5">Refunding request</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 max-md:gap-10 mb-10">
      <div class="flex flex-col gap-7">
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Reason of refund</h6>
          <p class="font-normal">**Waiting for backend**</p>
        </div>
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Bank name</h6>
          <p class="font-normal">**Waiting for backend**</p>
        </div>
      </div>
      <div class="flex flex-col gap-7">
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Account name</h6>
          <p class="font-normal">**Waiting for backend**</p>
        </div>
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Account number</h6>
          <p class="font-normal">**Waiting for backend**</p>
        </div>
      </div>
    </div>
    <h1 class="text-xl font-semibold mb-5">Refunding Slip</h1>
    <div
      class="w-[50%] flex items-center justify-between"
      *ngIf="isFileSelected"
    >
      <div class="flex items-center gap-4 h-28">
        <div *ngIf="fileUrl" class="w-24 h-full overflow-hidden">
          <img
            [src]="fileUrl"
            alt="Selected Image Preview"
            class="object-contain object-center"
          />
        </div>
        <div class="flex flex-col justify-between h-full py-4">
          <h1 class="font-semibold">{{ fileSelectedText }}</h1>
          <ng-container [ngSwitch]="fileSelectedType">
            <span
              class="font-medium text-sm text-[#7C7C7C]"
              *ngSwitchCase="'image/png'"
            >
              PNG file</span
            >
            <span
              class="font-medium text-sm text-[#7C7C7C]"
              *ngSwitchCase="'image/jpeg'"
            >
              JPEG file</span
            >
            <span class="font-medium text-sm text-[#7C7C7C]" *ngSwitchDefault>
              Wrong file type</span
            >
          </ng-container>
        </div>
      </div>
      <button (click)="onDeleteSelectedFIle()" class="text-[#7C7C7C]">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="bg-[#F4F4F4] py-8 px-6 flex items-center justify-between">
      <p class="font-medium text-sm">Upload Refunding Slip</p>

      <button
        (click)="uploadFile.click()"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <input
          type="file"
          (change)="selectFile($event)"
          hidden
          #uploadFile
          accept="image/*"
        />
        Upload Image
      </button>
    </div>
    <span *ngIf="errMsg" class="text-red-500 text-sm font-light">{{
      errMsg
    }}</span>
    <div class="grid grid-cols-1 sm:flex gap-6 sm:items-center mt-10">
      <button
        [disabled]="!isFileSelected"
        *ngIf="!isLoading"
        (click)="onSubmit(true)"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 border-2 border-[#399816] bg-white hover:bg-[#399816] hover:text-white text-[#399816] disabled:opacity-20 disabled:text-black disabled:border-black disabled:hover:bg-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <mat-icon>check</mat-icon>
        <span> Approve</span>
      </button>
      <button
        *ngIf="!isLoading"
        (click)="openDenyPopup()"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 border-2 border-[#E62147] bg-white hover:bg-[#E62147] hover:text-white text-[#E62147] rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <mat-icon>close</mat-icon>
        <span> Deny</span>
      </button>
      <button
        *ngIf="isLoading"
        class="relative transition ease-in-out delay-150 hover:scale-110 duration-150 mb-10 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <svg
          aria-hidden="true"
          class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        Processing...
      </button>
    </div>
  </div>
</div>
<app-loading *ngIf="isFullPageLoading"></app-loading>
