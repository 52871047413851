// auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient, private route: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}/auth/signin`, { email, password })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage
          const { data } = user;
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.http
      .get<any>(`${this.apiUrl}/auth/logout`)
      .subscribe((result: any) => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.route.navigate(['/login']);
      });
  }

  // Function to notify subscribers when the refresh token is updated
  public notifyRefreshTokenUpdated(token: string | null): void {
    this.refreshTokenSubject.next(token);
  }

  // Getter for the observable to subscribe to refresh token updates
  public get refreshTokenObservable(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  RefreshToken(): Observable<any> {
    console.log('RefreshToken');

    const currentUser = localStorage.getItem('currentUser');

    const refreshToken = JSON.parse(currentUser!).refreshToken;

    let header = new HttpHeaders({
      Authorization: 'Bearer ' + refreshToken,
    });
    return this.http.get<any>(`${this.apiUrl}/auth/refresh`, {
      headers: header,
    });
  }
}
