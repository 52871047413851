import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DataIncome, DataPlan } from 'src/app/model/admin.model';
import { ManageSubscriptionService } from 'src/app/services/manage-subscription.service';
import { useAnimation } from '@angular/animations';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // mockdata
  subscriptionPrice!: number;

  dataIncome!: DataIncome;

  dataPlan!: DataPlan;

  isFullPageLoading = false;

  constructor(
    private dataService: DataService,
    private subscriptionService: ManageSubscriptionService
  ) {}
  ngOnInit(): void {
    this.getDashboard();
  }
  getDashboard() {
    this.isFullPageLoading = true;
    this.dataService.getDashboard().subscribe({
      next: (data: any) => {
        console.log(data);

        this.dataIncome = data.data.dataIncome;
        this.dataPlan = data.data.dataPlan;

        this.getSubscriptionDetails();
      },
      error: (err) => console.log(err),
    });
  }

  getSubscriptionDetails() {
    this.subscriptionService.getSubscriptionPlan().subscribe({
      next: (data: any) => {
        const planFilter = data.data.filter(
          (item: any) => item.planName === this.dataPlan.planName
        );

        this.subscriptionPrice = planFilter[0].price;
        this.isFullPageLoading = false;
      },
      error: (err) => console.log(err),
      complete: () => (this.isFullPageLoading = false),
    });
  }
}
