import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, CanActivateChildFn } from '@angular/router';
import { AuthService } from './auth.service';
import { state } from '@angular/animations';

@Injectable()
export class PermissionsService {
  constructor(private authService: AuthService, public router: Router) {}

  canActivate(): boolean {
    const currentUser = this.authService.currentUserValue;
    // console.log(currentUser);

    if (currentUser) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}

export const authGuardGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};

export const authGuardChild: CanActivateChildFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};

export const authGuard: CanActivateFn = (route, state) => {
  return true;
};
