import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ManageSubscriptionService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiUrlWithPath = this.apiUrl + '/subscribe';

  constructor(private http: HttpClient) {}

  getSubscriptionPlan() {
    return this.http
      .get(`${this.apiUrlWithPath}/plan`)
      .pipe(catchError(this.handleError));
  }

  getSubscriptionWithParams(queryParams: any): Observable<any> {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get(`${this.apiUrlWithPath}/history`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => new Error(`${errorMessage}`));
  }
}
