<!-- dashboard.component.html -->
<div
  class="container sm:mx-auto pt-10 bg-white max-sm:mb-20"
  *ngIf="!isFullPageLoading"
>
  <h1 class="text-2xl font-semibold mb-10">Dashboard</h1>

  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6"
      *ngIf="dataIncome"
    >
      <h2 class="text-xl font-semibold mb-4">Income</h2>
      <div class="mb-6 grid grid-cols-2 border-b-2 pb-6">
        <div>
          <div class="text-gray-600 mb-1 text-sm">Total</div>
          <div class="text-2xl font-bold">
            {{ dataIncome.totalAll | currency : "THB " }}
          </div>
        </div>
        <div>
          <div class="text-gray-600 mb-1 text-sm">Today</div>
          <div class="text-2xl font-bold">
            {{ dataIncome.totalDay | currency : "THB " }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <div class="text-gray-600 mb-1 text-sm">This Week</div>
          <div class="font-medium">
            {{ dataIncome.totalWeek | currency : "THB " }}
          </div>
        </div>
        <div>
          <div class="text-gray-600 mb-1 text-sm">This Month</div>
          <div class="font-medium">
            {{ dataIncome.totalMonth | currency : "THB " }}
          </div>
        </div>
        <div>
          <div class="text-gray-600 mb-1 text-sm">This Year</div>
          <div class="font-medium">
            {{ dataIncome.totalYear | currency : "THB " }}
          </div>
        </div>
      </div>
      <a class="cursor-pointer mt-10 flex gap-5 text-[#1F639B]">
        <h3>View Income History</h3>
        <mat-icon>arrow_forward</mat-icon>
      </a>
    </div>

    <!-- Subscription.component.html -->
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 h-48"
      *ngIf="dataPlan"
    >
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Subscription</h2>
        <div class="text-xl bg-[#F4F4F4] p-2 px-4 rounded-full font-light">
          {{ dataPlan.planName }}
        </div>
      </div>
      <div class="flex justify-between items-center mb-4">
        <div class="text-sm text-gray-600">
          Next bill : {{ dataPlan.nextBill | date : "dd/MM/yy" }}
        </div>
        <div class="text-sm text-gray-600">
          {{ subscriptionPrice | currency : "THB " }}
        </div>
      </div>
      <!-- waiting for fix be -->
      <a class="cursor-pointer mt-10 flex gap-5 text-[#1F639B]">
        <h3>View Subscription</h3>
        <mat-icon>arrow_forward</mat-icon>
      </a>
    </div>
    <!-- <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 h-[70%]"
      *ngIf="participantRegister"
    >
      <h2 class="text-xl font-semibold mb-4">Participant Register</h2>
      <div class="mb-6">
        <div class="text-gray-600 mb-1">Total</div>
        <div class="text-lg font-bold">{{ participantRegister.totalAll }}</div>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <div class="text-gray-600 mb-1">Today</div>
          <span class="font-medium text-lg">{{
            participantRegister.totalDay
          }}</span>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Week</div>
          <span class="font-medium text-lg">{{
            participantRegister.totalWeek
          }}</span>
        </div>
        <div>
          <div class="text-gray-600 mb-1">This Month</div>
          <span class="font-medium text-lg">{{
            participantRegister.totalMonth
          }}</span>
        </div>
      </div>
    </div> -->
  </div>
</div>

<app-loading *ngIf="isFullPageLoading"></app-loading>
