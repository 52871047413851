import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;

    if (currentUser) {
      this.router.navigate(['/dashboard']);
    }
  }

  onLogin(form: NgForm): void {
    console.log(form.valid);
    const loginData = {
      email: form.value.email,
      password: form.value.password,
    };
    // Call a method in your AuthService to perform the login
    this.authService.login(loginData.email, loginData.password).subscribe({
      next: () => {
        // Navigate to the dashboard or other component upon successful login
        this.router.navigate(['/dashboard']);
      },
      error: (err) => {
        // Handle login error
        console.error(err);
      },
    });
  }
}
