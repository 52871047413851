import { Component } from '@angular/core';

@Component({
  selector: 'app-discard-popup',
  templateUrl: './discard-popup.component.html',
  styleUrls: ['./discard-popup.component.scss']
})
export class DiscardPopupComponent {

}
