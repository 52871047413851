<div class="container p-5">
  <div class="flex flex-col items-center">
    <div class="my-8">
      <mat-icon class="transform scale-[4] text-[#399816]"
        >check_circle_outline</mat-icon
      >
    </div>
    <h1
      *ngIf="!data?.isRefund"
      class="text-xl font-medium tracking-wide text-center"
    >
      Event info has been saved
    </h1>
    <h1
      *ngIf="data?.isRefund && data?.isApprove"
      class="text-xl font-medium tracking-wide text-center"
    >
      Refund has been approve
    </h1>
    <h1
      *ngIf="data?.isRefund && !data?.isApprove"
      class="text-xl font-medium tracking-wide text-center"
    >
      Refund has been denied
    </h1>

    <div class="grid grid-cols-1 mt-10 w-72">
      <button
        [mat-dialog-close]="true"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5 w-full"
      >
        <span>Done</span>
      </button>
    </div>
  </div>
</div>
