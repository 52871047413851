<div class="container p-5">
  <div class="flex flex-col items-center">
    <div class="my-8">
      <mat-icon class="transform scale-[4]">error_outline</mat-icon>
    </div>
    <div class="flex flex-col items-center" *ngIf="data.type === 'closed'">
      <h1 class="text-xl font-medium tracking-wide text-center">
        Are you sure to Permanent Close register?
      </h1>
      <p class="text-sm font-light text-[#7C7C7C] mt-2.5">
        User will be unable to register this event after this.
      </p>
    </div>

    <div class="flex flex-col items-center" *ngIf="data.type === 'cancelled'">
      <h1 class="text-xl font-medium tracking-wide text-center">
        Are you sure to cancel event?
      </h1>
    </div>
    <div class="flex flex-col items-center gap-4 mt-4">
      <label for="password" class="text-base font-light text-[#7C7C7C] mt-2.5">
        Please fill your admin password to confirm action
      </label>
      <input
        type="password"
        placeholder="Admin password"
        name="password"
        [formControl]="password"
        class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light w-full"
        required
      />
      <span *ngIf="errMsg" class="text-red-500 text-sm font-light">{{
        errMsg
      }}</span>
    </div>
    <div
      class="grid grid-cols-1 mt-10 w-full gap-4 sm:flex sm:items-center sm:justify-between"
    >
      <button
        (click)="onEventAction(data.type)"
        *ngIf="data.type === 'closed'"
        class="w-full transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Close register</span>
      </button>
      <button
        *ngIf="data.type === 'closed'"
        [mat-dialog-close]="false"
        class="w-full transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Keep open</span>
      </button>
      <button
        (click)="onEventAction(data.type)"
        *ngIf="data.type === 'cancelled'"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-red-500 border-2 hover:border-red-500 hover:bg-red-500 border-red-500 text-white hover:text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Cancel Event</span>
      </button>
      <button
        *ngIf="data.type === 'cancelled'"
        [mat-dialog-close]="false"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Keep Event</span>
      </button>
    </div>
  </div>
</div>
