import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { RootComponent } from './root/root.component';
import { DashboardComponent } from './pages/dashboard-page/dashboard.component';
import { authGuardChild, authGuardGuard } from '../services/auth.guard';

import { ManageEventComponent } from './pages/manage-event-page/manage-event.component';
import { RefundApprovalComponent } from './pages/refund-approval-page/refund-approval.component';
import { SubscriptionComponent } from './pages/subscription-page/subscription.component';
import { EventDetailComponent } from './pages/event-detail-page/event-detail.component';
import { CreateEventPageComponent } from './pages/create-event-page/create-event-page.component';
import { EditEventPageComponent } from './pages/edit-event-page/edit-event-page.component';
import { canDeactivateGuard } from '../services/can-deactivate.guard';
import { RefundRequestPageComponent } from './pages/refund-request-page/refund-request-page.component';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    canActivate: [authGuardGuard],
    canActivateChild: [authGuardChild],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'manage-event',
        component: ManageEventComponent,
      },
      {
        path: 'manage-event/:id',
        component: EventDetailComponent,
      },
      {
        path: 'refund',
        component: RefundApprovalComponent,
      },
      {
        path: 'refund/:id',
        component: RefundRequestPageComponent,
      },
      // {
      //   path: 'subscription',
      //   component: SubscriptionComponent,
      // },
      {
        path: 'create-event',
        component: CreateEventPageComponent,
      },
      {
        path: 'edit-event/:id',
        component: EditEventPageComponent,
        canDeactivate: [canDeactivateGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
