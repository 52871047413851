<!-- waiting for fix backend -->
<!-- no api for edit event -->
<div
  *ngIf="!isFullPageLoading"
  class="container sm:mx-auto sm:ps-10 pt-6 bg-white flex flex-col mb-10"
>
  <div class="cursor-pointer mb-6">
    <span routerLink="/admin/manage-event">Manage Event</span>
    <span> &nbsp; > &nbsp;</span>
    <span [routerLink]="['/admin/manage-event', id]">
      {{ eventDetail.eventName }}
    </span>
    <span> &nbsp; > &nbsp;</span>
    <span class="font-bold"> Edit Event </span>
  </div>
  <h1 class="text-3xl font-semibold mb-10">Edit Event</h1>
  <form [formGroup]="createForm" class="group">
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-4"
    >
      <h2 class="text-2xl font-semibold mb-6">Event Info</h2>
      <div class="grid grid-cols-1">
        <div class="flex flex-col gap-2 mb-5">
          <label for="eventName" class="text-sm">Event name</label>
          <input
            type="text"
            formControlName="eventName"
            name="eventName"
            placeholder="Event name"
            class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
            [ngClass]="isInputError('eventName') ? 'border border-red-500' : ''"
          />
          <span
            *ngIf="isInputError('eventName')"
            class="text-red-500 text-sm font-light"
            >Please enter the event name</span
          >
        </div>
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 mb-5">
          <div class="flex flex-col gap-2">
            <label for="date" class="text-sm">Date</label>
            <input
              type="date"
              formControlName="date"
              name="date"
              placeholder="DD/MM/YY"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
              [ngClass]="isInputError('date') ? 'border border-red-500' : ''"
            />
            <span
              *ngIf="isInputError('date')"
              class="text-red-500 text-sm font-light"
              >Please enter the event date</span
            >
          </div>
          <div class="flex flex-col gap-2">
            <label for="timeStart" class="text-sm">Time</label>
            <div class="flex items-center justify-between">
              <input
                type="time"
                formControlName="timeStart"
                name="timeStart"
                placeholder="00 : 00"
                class="bg-[#F4F4F4] w-[45%] p-2 px-4 rounded-full font-light"
                [ngClass]="
                  isInputError('timeStart') ? 'border border-red-500' : ''
                "
              />
              <span>-</span>
              <input
                type="time"
                formControlName="timeEnd"
                name="timeEnd"
                placeholder="00 : 00"
                class="bg-[#F4F4F4] w-[45%] p-2 px-4 rounded-full font-light"
                [ngClass]="
                  isInputError('timeEnd') ? 'border border-red-500' : ''
                "
              />
            </div>
            <span
              *ngIf="isInputError('timeStart') || isInputError('timeEnd')"
              class="text-red-500 text-sm font-light"
              >Please enter the start and end times for the event</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-2 mb-5">
          <label for="locationName" class="text-sm">Location</label>
          <input
            type="text"
            formControlName="locationName"
            name="locationName"
            placeholder="Location"
            class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
            [ngClass]="
              isInputError('locationName') ? 'border border-red-500' : ''
            "
          />
          <span
            *ngIf="isInputError('locationName')"
            class="text-red-500 text-sm font-light"
            >Please enter the event location</span
          >
        </div>
        <div class="flex flex-col gap-2">
          <label for="praticipantNumber" class="text-sm"
            >Participant number</label
          >
          <div
            class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light flex items-center justify-between md:w-[40%]"
          >
            <a
              class="flex items-center justify-center active:ring-2 active:ring-red-700 rounded-xl p-2"
              (click)="decreaseParticipant()"
              ><mat-icon class="cursor-pointer">remove</mat-icon></a
            >
            <span>{{ createForm.get("praticipantNumber")?.value }}</span
            ><a
              class="flex items-center justify-center active:ring-2 active:ring-green-700 rounded-xl p-2"
              (click)="increaseParticipant()"
              ><mat-icon class="cursor-pointer">add</mat-icon></a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-10"
    >
      <h2 class="text-2xl font-semibold mb-6">Payment Condition</h2>
      <p
        *ngIf="isInputError('paymentCondition')"
        class="text-red-500 text-sm font-light mb-4"
      >
        Please enter the event payment condition
      </p>
      <!-- advance condition -->
      <div class="mb-4">
        <div class="flex items-start gap-4 mb-5">
          <div>
            <div
              (click)="selectCondition('ADVANCE')"
              class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              [ngClass]="
                isInputError('paymentCondition') ? ' border-red-500' : ''
              "
            >
              <div
                class="z-10 rounded-full w-full h-full"
                [ngClass]="
                  createForm.get('paymentCondition')?.value === 'ADVANCE'
                    ? ' bg-black'
                    : ''
                "
              ></div>
            </div>
          </div>
          <div>
            <h3 class="text-lg font-normals mb-2">Advance Payment Require</h3>
            <p class="text-xs font-thin text-[#7C7C7C]">
              Users must make a payment before joining the event to confirm
              their participation.
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 max-lg:gap-4 lg:grid-cols-2"
          *ngIf="createForm.get('paymentCondition')?.value === 'ADVANCE'"
        >
          <div class="flex flex-col gap-2">
            <label for="price" class="text-sm">Event Price</label>
            <div class="flex items-center gap-2">
              <input
                type="text"
                formControlName="price"
                name="price"
                placeholder="Event price"
                class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
                [ngClass]="isInputError('price') ? 'border border-red-500' : ''"
                (input)="onlyNumber($event, 'price')"
              />
              <span class="text-xl">THB</span>
            </div>
            <span
              *ngIf="isInputError('price')"
              class="text-red-500 text-sm font-light"
              >Please enter the event price</span
            >
          </div>
          <div class="flex flex-col max-lg:items-center">
            <span class="text-sm mb-3">Price / Participant</span>
            <h2 class="text-xl font-semibold">THB {{ pricePerparticipant }}</h2>
          </div>
        </div>
      </div>
      <!-- Upon condition -->
      <div class="mb-4">
        <div class="flex items-start gap-4 mb-5">
          <div>
            <div
              (click)="selectCondition('UPON')"
              class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              [ngClass]="
                isInputError('paymentCondition') ? ' border-red-500' : ''
              "
            >
              <div
                class="z-10 rounded-full w-full h-full"
                [ngClass]="
                  createForm.get('paymentCondition')?.value === 'UPON'
                    ? ' bg-black'
                    : ''
                "
              ></div>
            </div>
          </div>
          <div>
            <h3 class="text-lg font-normals mb-2">
              Payment Upon Event Capacity
            </h3>
            <p class="text-xs font-thin text-[#7C7C7C]">
              Users can join without immediate payment. System will notify users
              to make a payment when event is full.
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 max-lg:gap-4 lg:grid-cols-2"
          *ngIf="createForm.get('paymentCondition')?.value === 'UPON'"
        >
          <div class="flex flex-col gap-2 transition">
            <label for="price" class="text-sm">Event Price</label>
            <div class="flex items-center gap-2">
              <input
                type="text"
                formControlName="price"
                name="price"
                placeholder="Event price"
                class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
                [ngClass]="isInputError('price') ? 'border border-red-500' : ''"
                (input)="onlyNumber($event, 'price')"
              />
              <span class="text-xl">THB</span>
            </div>
            <span
              *ngIf="isInputError('price')"
              class="text-red-500 text-sm font-light"
              >Please enter the event price</span
            >
          </div>
          <div class="flex flex-col max-lg:items-center">
            <span class="text-sm mb-3">Price / Participant</span>
            <h2 class="text-xl font-semibold">THB {{ pricePerparticipant }}</h2>
          </div>

          <label class="inline-flex items-center cursor-pointer mt-5">
            <!-- waiting for fix be -->
            <input
              type="checkbox"
              class="sr-only peer"
              [checked]="isSeperate"
              (change)="isSeperate = !isSeperate"
            />
            <div
              class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-black dark:peer-focus:ring-black rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"
            ></div>
            <span class="ms-3 text-lg font-normal">Seperate Payment</span>
          </label>
        </div>
        <div
          *ngIf="
            isSeperate && createForm.get('paymentCondition')?.value === 'UPON'
          "
          class="grid grid-cols-1 sm:grid-cols-2 mt-4"
        >
          <div class="flex items-center gap-2">
            <div>
              <div
                (click)="selectSeperatePayment(true)"
                class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
                [ngClass]="
                  isInputError('paymentCondition') ? ' border-red-500' : ''
                "
              >
                <div
                  class="z-10 rounded-full w-full h-full"
                  [ngClass]="isFixed ? ' bg-black' : ''"
                ></div>
              </div>
            </div>
            <p>Fixed by number of Participant limit</p>
          </div>
          <div class="flex items-center gap-2">
            <div>
              <div
                (click)="selectSeperatePayment(false)"
                class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              >
                <div
                  class="z-10 rounded-full w-full h-full"
                  [ngClass]="isAdjust ? ' bg-black' : ''"
                ></div>
              </div>
            </div>
            <p>Adjusting follow number of confirmed participant</p>
          </div>
        </div>
      </div>
      <!-- Without condition -->
      <div class="mb-4">
        <div class="flex items-start gap-4 mb-5">
          <div>
            <div
              (click)="selectCondition('WITHOUT')"
              class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              [ngClass]="
                isInputError('paymentCondition') ? ' border-red-500' : ''
              "
            >
              <div
                class="z-10 rounded-full w-full h-full"
                [ngClass]="
                  createForm.get('paymentCondition')?.value === 'WITHOUT'
                    ? ' bg-black'
                    : ''
                "
              ></div>
            </div>
          </div>
          <div>
            <h3 class="text-lg font-normals mb-2">Join Without Payment</h3>
            <p class="text-xs font-thin text-[#7C7C7C]">
              Users can join the event without an upfront payment, and payment
              can be processed at a later time.
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 max-lg:gap-4 lg:grid-cols-2"
          *ngIf="createForm.get('paymentCondition')?.value === 'WITHOUT'"
        >
          <div class="flex flex-col gap-2 transition">
            <label for="price" class="text-sm">Event Price</label>
            <div class="flex items-center gap-2">
              <input
                type="text"
                formControlName="price"
                name="price"
                placeholder="Event price"
                class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light"
                [ngClass]="isInputError('price') ? 'border border-red-500' : ''"
                (input)="onlyNumber($event, 'price')"
              />
              <span class="text-xl">THB</span>
            </div>
            <span
              *ngIf="isInputError('price')"
              class="text-red-500 text-sm font-light"
              >Please enter the event price</span
            >
          </div>
          <div class="flex flex-col max-lg:items-center">
            <span class="text-sm mb-3">Price / Participant</span>
            <h2 class="text-xl font-semibold">THB {{ pricePerparticipant }}</h2>
          </div>

          <label class="inline-flex items-center cursor-pointer mt-5">
            <!-- waiting for fix be -->
            <input
              type="checkbox"
              class="sr-only peer"
              [checked]="isSeperate"
              (change)="isSeperate = !isSeperate"
            />
            <div
              class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-black dark:peer-focus:ring-black rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"
            ></div>
            <span class="ms-3 text-lg font-normal">Seperate Payment</span>
          </label>
        </div>
        <div
          *ngIf="
            isSeperate &&
            createForm.get('paymentCondition')?.value === 'WITHOUT'
          "
          class="grid grid-cols-1 sm:grid-cols-2 mt-4"
        >
          <div class="flex items-center gap-2">
            <div>
              <div
                (click)="selectSeperatePayment(true)"
                class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              >
                <div
                  class="z-10 rounded-full w-full h-full"
                  [ngClass]="isFixed ? ' bg-black' : ''"
                ></div>
              </div>
            </div>
            <p>Fixed by number of Participant limit</p>
          </div>
          <div class="flex items-center gap-2">
            <div>
              <div
                (click)="selectSeperatePayment(false)"
                class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              >
                <div
                  class="z-10 rounded-full w-full h-full"
                  [ngClass]="isAdjust ? ' bg-black' : ''"
                ></div>
              </div>
            </div>
            <p>Adjusting follow number of confirmed participant</p>
          </div>
        </div>
      </div>
      <!-- free condition -->
      <div class="mb-4">
        <div class="flex items-start gap-4 mb-5">
          <div>
            <div
              (click)="selectCondition('FREE')"
              class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
              [ngClass]="
                isInputError('paymentCondition') ? ' border-red-500' : ''
              "
            >
              <div
                class="z-10 rounded-full w-full h-full"
                [ngClass]="
                  createForm.get('paymentCondition')?.value === 'FREE'
                    ? ' bg-black'
                    : ''
                "
              ></div>
            </div>
          </div>
          <div>
            <h3 class="text-lg font-normals mb-2">Free Event</h3>
            <p class="text-xs font-thin text-[#7C7C7C]">
              The price of joining event will set at 0
            </p>
          </div>
        </div>
        <label
          *ngIf="createForm.get('paymentCondition')?.value === 'FREE'"
          class="inline-flex items-center cursor-pointer"
        >
          <!-- waiting for fix be -->
          <input
            type="checkbox"
            class="sr-only peer"
            [checked]="isAutoApprove"
            (change)="isAutoApprove = !isAutoApprove"
          />
          <div
            class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-black dark:peer-focus:ring-black rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"
          ></div>
          <span class="ms-3 text-lg font-normal"
            >Automatic approve participant?</span
          >
        </label>
      </div>
      <label class="inline-flex items-center cursor-pointer">
        <!-- waiting for fix be -->
        <input
          type="checkbox"
          class="sr-only peer"
          formControlName="isRefund"
          (change)="changeIsRefund($event)"
        />
        <div
          class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-black dark:peer-focus:ring-black rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"
        ></div>
        <span class="ms-3 text-lg font-normal">Customer are refundable</span>
      </label>
    </div>
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-10"
    >
      <h2 class="text-2xl font-semibold mb-6">Timer Configuration</h2>
      <!-- close registration time -->
      <div class="flex flex-col gap-2 mb-5">
        <div class="flex gap-4">
          <label for="closeHrs" class="text-sm">Close registration</label>
          <span
            *ngIf="createForm.get('timeClose')?.value"
            class="text-sm font-light"
            >{{ createForm.get("timeClose")?.value }} hrs</span
          >
        </div>

        <div class="grid grid-cols-1 gap-2 md:flex md:items-center md:gap-10">
          <div class="flex items-center">
            <input
              type="text"
              formControlName="closeHrs"
              name="closeHrs"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeClose') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeClose', $event)"
            />
            <span class="ms-3 text-lg font-normal">Hrs</span>
          </div>
          <div class="flex items-center">
            <input
              type="text"
              formControlName="closeMins"
              name="closeMins"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeClose') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeClose', $event)"
            />
            <span class="ms-3 text-lg font-normal">Mins</span>
          </div>
        </div>

        <span
          *ngIf="isInputError('timeClose')"
          class="text-red-500 text-sm font-light"
          >Please enter the event close registration</span
        >
      </div>
      <!-- waiting time -->
      <div class="flex flex-col gap-2 mb-5">
        <div class="flex gap-4">
          <label for="waitingHrs" class="text-sm">Waiting list deadline</label>
          <span
            *ngIf="createForm.get('timeWaitingEnd')?.value"
            class="text-sm font-light"
            >{{ createForm.get("timeWaitingEnd")?.value }} hrs</span
          >
        </div>
        <p class="text-xs font-thin text-[#7C7C7C]">
          Deadline for the user in the waiting list to join the main list before
          notifying the next yser in the waiting list.
        </p>

        <div class="grid grid-cols-1 gap-2 md:flex md:items-center md:gap-10">
          <div class="flex items-center">
            <input
              type="text"
              formControlName="waitingHrs"
              name="waitingHrs"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeWaitingEnd') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeWaitingEnd', $event)"
            />
            <span class="ms-3 text-lg font-normal">Hrs</span>
          </div>
          <div class="flex items-center">
            <input
              type="text"
              formControlName="waitingMins"
              name="waitingMins"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeWaitingEnd') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeWaitingEnd', $event)"
            />
            <span class="ms-3 text-lg font-normal">Mins</span>
          </div>
        </div>

        <span
          *ngIf="isInputError('timeWaitingEnd')"
          class="text-red-500 text-sm font-light"
          >Please enter the event waiting list deadline</span
        >
      </div>
      <!-- checkout time -->
      <div class="flex flex-col gap-2">
        <div class="flex gap-4">
          <label for="checkoutHrs" class="text-sm"
            >Checkout Processing Time</label
          >
          <span
            *ngIf="createForm.get('timeCheckout')?.value"
            class="text-sm font-light"
            >{{ createForm.get("timeCheckout")?.value }} hrs</span
          >
        </div>

        <div class="grid grid-cols-1 gap-2 md:flex md:items-center md:gap-10">
          <div class="flex items-center">
            <input
              type="text"
              formControlName="checkoutHrs"
              name="checkoutHrs"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeCheckout') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeCheckout', $event)"
            />
            <span class="ms-3 text-lg font-normal">Hrs</span>
          </div>
          <div class="flex items-center">
            <input
              type="text"
              formControlName="checkoutMins"
              name="checkoutMins"
              placeholder="00"
              class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light md:w-32"
              [ngClass]="
                isInputError('timeCheckout') ? 'border border-red-500' : ''
              "
              (input)="onTimeInput('timeCheckout', $event)"
            />
            <span class="ms-3 text-lg font-normal">Mins</span>
          </div>
        </div>

        <span
          *ngIf="isInputError('timeCheckout')"
          class="text-red-500 text-sm font-light"
          >Please enter the event checkout processing time</span
        >
      </div>
    </div>
    <span *ngIf="errMsg" class="text-red-500 text-sm font-light ps-2">{{
      errMsg
    }}</span>
    <div class="flex items-center gap-4 mb-10" *ngIf="!isLoading">
      <button
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Save Change</span>
      </button>
      <button
        (click)="onBack()"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Cancel</span>
      </button>
    </div>
    <button
      *ngIf="isLoading"
      class="relative transition ease-in-out delay-150 hover:scale-110 duration-150 mb-10 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
    >
      <svg
        aria-hidden="true"
        class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      Processing...
    </button>
  </form>
</div>
<app-loading *ngIf="isFullPageLoading"></app-loading>
