import { Component, OnInit } from '@angular/core';
import { EventData } from 'src/app/model/admin.model';
import { ManageEventService } from 'src/app/services/manage-event.service';

@Component({
  selector: 'app-manage-event',
  templateUrl: './manage-event.component.html',
  styleUrls: ['./manage-event.component.scss'],
})
export class ManageEventComponent implements OnInit {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  events!: EventData[];
  isFullPageLoading = false;
  isLoading = false;

  statusChoice = [
    { view: 'Open', viewValue: 'open' },
    { view: 'Closed', viewValue: 'closed' },
    { view: 'Cancelled', viewValue: 'cancelled' },
    { view: 'Ended', viewValue: 'ended' },
  ];

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    dateStart: null,
    dateEnd: null,
    status: 'all',
    sortBy: null,
    sortOrderBy: 'desc',
  };

  constructor(private manageEventService: ManageEventService) {}

  ngOnInit(): void {
    this.getEvent(true);
  }

  getEvent(isInit: boolean) {
    if (isInit) {
      this.isFullPageLoading = true;
    }
    this.isLoading = true;
    this.manageEventService.getEventWithParams(this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.totalCount = data.data.totalCount;
        this.events = data.data.totalData;
        this.isFullPageLoading = false;
        this.isLoading = false;
      },
      error: (err) => console.log(err),
      complete: () => {
        this.isFullPageLoading = false;
        this.isLoading = false;
      },
    });
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      this.queryParams.sortBy = null;
    } else {
      this.queryParams.sortBy = sortType;
    }

    this.getEvent(false);
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.getEvent(false);
    }
  }

  resetFilter() {
    this.currentPage = 1;
    this.pageSize = 10;
    this.queryParams = {
      skip: this.currentPage,
      limit: this.pageSize,
      search: null,
      dateStart: null,
      dateEnd: null,
      status: 'all',
      sortBy: null,
      sortOrderBy: 'desc',
    };

    this.getEvent(false);
  }
}
