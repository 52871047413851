import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageEventService } from 'src/app/services/manage-event.service';

@Component({
  selector: 'app-close-event-popup',
  templateUrl: './close-event-popup.component.html',
  styleUrls: ['./close-event-popup.component.scss'],
})
export class CloseEventPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CloseEventPopupComponent>,
    private manageEventService: ManageEventService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  password = new FormControl('');

  errMsg = '';

  ngOnInit(): void {
    console.log(this.data);
  }

  onEventAction(action: string) {
    if (this.password.invalid) {
      this.errMsg = 'Please enter admin password';
    } else {
      const body = { status: action, password: this.password.value };
      this.manageEventService.editEventStatus(body, this.data.id).subscribe({
        next: (data) => {},
        error: (err) => (this.errMsg = err),
        complete: () => {
          this.dialogRef.close(true);
        },
      });
    }
  }
}
