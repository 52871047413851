<div class="container p-5">
  <div class="flex items-center gap-2 mb-5 md:w-[600px]">
    <mat-icon class="text-green-700">check_circle_outline</mat-icon>
    <h1 class="text-2xl font-semibold">Event has been created</h1>
  </div>

  <div class="flex justify-between items-center my-4">
    <h2 class="text-2xl font-semibold">Event Visibility</h2>
    <div class="w-40 sm:w-52">
      <div mat-button [matMenuTriggerFor]="shareMenu" class="w-full">
        <button
          type="button"
          class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#F4F4F4] rounded-full focus:ring-4 focus:ring-gray-300 p-2 w-full px-4"
        >
          <div class="flex items-center justify-between">
            <span>Public</span>
            <span class="ms-4 flex items-center justify-center">
              <mat-icon>expand_more</mat-icon></span
            >
          </div>
        </button>
        <!-- dropdown for share link -->
        <mat-menu
          #shareMenu="matMenu"
          yPosition="below"
          xPosition="before"
          class="sm:w-52"
        >
          <button mat-menu-item class="max-sm:text-sm">
            <span>Public</span>
          </button>
          <button mat-menu-item class="max-sm:text-sm">
            <span>Anyone with the link</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- copy link -->
  <div class="mb-4 w-full">
    <p class="text-gray-600 mb-2">Event Link</p>
    <div class="flex justify-between items-center w-full">
      <input
        type="text"
        class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light w-[95%] text-nowrap overflow-y-scroll"
        readonly
      />

      <button
        [cdkCopyToClipboard]="'test'"
        class="flex items-center justify-center active:ring-2 active:ring-green-700 rounded-xl p-2"
      >
        <mat-icon>filter_none</mat-icon>
      </button>
    </div>
    <p class="text-gray-600 mt-2">Everyone can access an event info</p>
  </div>
  <button
    mat-dialog-close
    class="transition ease-in-out delay-150 hover:scale-110 duration-150 mt-10 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
  >
    <span>Done</span>
  </button>
</div>
