import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventForm } from '../model/admin.model';

@Injectable({
  providedIn: 'root',
})
export class ManageEventService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiUrlWithPath = this.apiUrl + '/backoffice/event';

  constructor(private http: HttpClient) {}

  getEventWithParams(queryParams: any): Observable<any> {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(this.apiUrlWithPath, requestOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  getEventById(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrlWithPath}/${id}`)
      .pipe(catchError(this.handleError));
  }

  createEvent(formData: EventForm): Observable<any> {
    return this.http
      .post<any>(this.apiUrlWithPath, formData)
      .pipe(catchError(this.handleError));
  }

  editEventStatus(status: any, eventId: string): Observable<any> {
    console.log(status);

    return this.http
      .patch<any>(`${this.apiUrlWithPath}/${eventId}`, status)
      .pipe(catchError(this.handleError));
  }

  getParticipantByEventId(id: string, queryParams: any): Observable<any> {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiUrlWithPath}/participant/${id}`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getParticipantByUserId(id: string, userId: string): Observable<any> {
    let params = new HttpParams();

    params = params.set('userId', userId);

    const requestOptions = {
      params: params,
    };

    return this.http
      .get<any>(
        `${this.apiUrlWithPath}/participant/detail/${id}`,
        requestOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateRefundUser(id: string, formData: FormData): Observable<any> {
    return this.http
      .patch<any>(`${this.apiUrlWithPath}/refund/${id}`, formData)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => errorMessage);
  }
}
