<div
  class="container sm:mx-auto sm:ps-10 pt-10 bg-white flex flex-col mb-10"
  *ngIf="!isFullPageLoading"
>
  <h1 class="text-3xl font-semibold mb-10">Manage Event</h1>
  <div
    class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mb-10"
  >
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div>
        <div class="text-sm mb-4">Date</div>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 lg:px-2">
          <mat-form-field color="primary">
            <!-- <mat-label>MM/DD/YYYY</mat-label> -->
            <input
              matInput
              [matDatepicker]="picker1"
              [(ngModel)]="queryParams.dateStart"
              placeholder="Start Date"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field color="primary">
            <!-- <mat-label>MM/DD/YYYY</mat-label> -->
            <input
              matInput
              [min]="queryParams?.dateStart"
              [matDatepicker]="picker2"
              [(ngModel)]="queryParams.dateEnd"
              placeholder="End Date"
              [disabled]="!queryParams?.dateStart"
            />

            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker2
              color="primary"
              [disabled]="!queryParams?.dateStart"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="text-sm mb-4">Status</div>
        <div class="grid grid-cols-1">
          <mat-form-field>
            <!-- <mat-label>All Status</mat-label> -->
            <mat-select
              placeholder="All Status"
              [(ngModel)]="queryParams.status"
            >
              <mat-option
                *ngFor="let status of statusChoice"
                [value]="status.viewValue"
              >
                {{ status.view }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:flex gap-2.5 mt-10">
      <!-- search btn -->
      <button
        (click)="getEvent(false)"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <mat-icon>search</mat-icon><span>Search</span>
      </button>
      <!-- reset btn -->
      <button
        (click)="resetFilter()"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <mat-icon>replay</mat-icon><span>Reset Filter</span>
      </button>
    </div>
  </div>
  <h1 class="text-2xl font-semibold mb-10" *ngIf="totalCount">
    Event list ({{ totalCount }})
  </h1>
  <div class="md:flex md:justify-between md:items-start" *ngIf="events">
    <!-- search by keyword box -->
    <mat-form-field class="w-full md:w-80">
      <input
        matInput
        type="text"
        [(ngModel)]="queryParams.search"
        placeholder="Search event list"
      />
      <button
        *ngIf="queryParams.search"
        matSuffix
        mat-icon-keyword
        aria-label="Clear"
        (click)="queryParams.search = null"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <!-- create event -->
    <button
      routerLink="/admin/create-event"
      class="transition ease-in-out delay-150 hover:scale-110 duration-150 max-md:mb-10 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
    >
      <mat-icon>add</mat-icon><span>Create Event</span>
    </button>
  </div>
  <div class="flex flex-col mt-6">
    <div class="overflow-x-auto">
      <table
        class="min-w-full table-fixed border-separate border-spacing-2"
        *ngIf="!isLoading"
      >
        <thead>
          <tr class="text-left cursor-pointer">
            <th
              class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[30%]"
              (click)="sortBy('eventName')"
            >
              <span class="text-center">Event name</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'eventName'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] font-light relative text-center"
              (click)="sortBy('date')"
            >
              <span class="text-center">Date</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'date'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] relative text-center font-light"
              (click)="sortBy('time')"
            >
              <span class="text-center">Time</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'time'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] relative text-center font-light"
              (click)="sortBy('duration')"
            >
              <span class="text-center">Duration</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'duration'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] relative text-center font-light"
              (click)="sortBy('status')"
            >
              <span class="text-center">Registration status</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'status'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let event of events">
            <tr
              [ngClass]="
                event.status === 'ended' || event.status === 'cancelled'
                  ? 'text-[#7C7C7C]'
                  : 'text-black'
              "
              class="bg-white border-2 shadow rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
            >
              <td class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate">
                <span
                  [routerLink]="['/admin/manage-event', event._id]"
                  [ngClass]="
                    event.status === 'open' || event.status === 'closed'
                      ? 'text-[#1F639B] cursor-pointer'
                      : event.status === 'cancelled'
                      ? 'line-through'
                      : ''
                  "
                  >{{ event.eventName }}</span
                >
                <span
                  *ngIf="event.status === 'open'"
                  class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-white bg-[#13222C] rounded-full"
                  >{{ event.count }}</span
                >
              </td>
              <td class="px-6 py-4 truncate">
                {{ event.dateStart | date : "dd/MM/yy" }}
              </td>
              <td class="px-6 py-4 truncate">
                {{ event.timeStart }} -
                {{ event.timeEnd }}
              </td>
              <td class="px-6 py-4 truncate">{{ event.duration }}</td>
              <td class="px-6 py-4 rounded-tr-lg rounded-br-lg truncate">
                <span
                  [ngClass]="event.status === 'cancelled' ? 'text-red-400' : ''"
                  >{{ event.status | titlecase }}</span
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <app-part-loading *ngIf="isLoading"></app-part-loading>
    </div>

    <div class="flex justify-center items-center mt-4">
      <div class="flex items-center">
        <mat-icon
          (click)="changePage(currentPage - 1)"
          [ngClass]="{ disabled: currentPage === 1 }"
          >chevron_left</mat-icon
        >
        <span>{{ currentPage }}/{{ totalPages }}</span>
        <mat-icon
          (click)="changePage(currentPage + 1)"
          [ngClass]="{ disabled: currentPage === totalPages }"
          >chevron_right</mat-icon
        >
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="isFullPageLoading"></app-loading>
