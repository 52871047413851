<div
  class="container sm:mx-auto sm:ps-10 pt-6 bg-white flex flex-col mb-10"
  *ngIf="!isFullPageLoading"
>
  <div class="cursor-pointer mb-4">
    <span routerLink="/admin/manage-event">Manage Event</span>
    <span> &nbsp; > &nbsp;</span>
    <span class="font-bold"> {{ eventDetail.eventName }} </span>
  </div>
  <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-4">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-semibold">{{ eventDetail.eventName }}</h2>
      <div class="w-40 sm:w-52" *ngIf="eventDetail.status !== 'cancelled'">
        <div mat-button [matMenuTriggerFor]="actionMenu" class="w-full">
          <button
            type="button"
            class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#F4F4F4] rounded-full focus:ring-4 focus:ring-gray-300 p-2 w-full px-4"
          >
            <div class="flex items-center justify-between">
              <span>Action</span>
              <span class="ms-4 flex items-center justify-center">
                <mat-icon>expand_more</mat-icon></span
              >
            </div>
          </button>
          <!-- dropdown for action -->
          <mat-menu
            #actionMenu="matMenu"
            yPosition="below"
            xPosition="before"
            class="sm:w-52"
          >
            <button
              [routerLink]="['/admin/edit-event', id]"
              mat-menu-item
              class="max-sm:text-sm"
            >
              <mat-icon>edit</mat-icon><span>Edit Info</span>
            </button>
            <button
              mat-menu-item
              class="max-sm:text-sm"
              (click)="openClosePermission('closed')"
              [disabled]="eventDetail.status === 'closed'"
            >
              <mat-icon>block</mat-icon><span>Close Register</span>
            </button>
            <button
              mat-menu-item
              style="color: red"
              class="max-sm:text-sm"
              (click)="openClosePermission('cancelled')"
            >
              <mat-icon style="color: red">close</mat-icon
              ><span>Cancel Event</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 border-b-2 pb-6 border-opacity-20 border-black text-gray-600"
    >
      <div class="flex flex-col gap-5 max-md:mb-4">
        <div class="flex flex-col gap-1">
          <h6 class="text-sm font-medium">Date</h6>
          <p class="font-medium">
            {{ eventDetail.dateEnd | date : "fullDate" }}
          </p>
          <div class="text-sm">
            <span>{{ eventDetail.timeStart }}</span>
            <span> - </span> <span>{{ eventDetail.timeEnd }}</span> &nbsp;<span
              >({{ eventDetail.duration }})</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <h6 class="text-sm font-medium">Location</h6>
          <p class="font-medium">
            {{ eventDetail.locationName }}
          </p>
        </div>
      </div>
      <div class="flex flex-col max-md:gap-4 md:justify-between">
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">Status</h6>
          <div>
            <span class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light">
              {{ status }}</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <h6 class="text-sm font-medium">No. of Participant</h6>
          <div class="flex items-center">
            <span>{{ eventDetail.praticipantNumber }}</span
            ><mat-icon>person_outline</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center my-4">
      <h2 class="text-2xl font-semibold">Event Visibility</h2>
      <div class="w-40 sm:w-52">
        <div mat-button [matMenuTriggerFor]="shareMenu" class="w-full">
          <button
            type="button"
            class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#F4F4F4] rounded-full focus:ring-4 focus:ring-gray-300 p-2 w-full px-4"
          >
            <div class="flex items-center justify-between">
              <span>Public</span>
              <span class="ms-4 flex items-center justify-center">
                <mat-icon>expand_more</mat-icon></span
              >
            </div>
          </button>
          <!-- dropdown for share link -->
          <mat-menu
            #shareMenu="matMenu"
            yPosition="below"
            xPosition="before"
            class="sm:w-52"
          >
            <button mat-menu-item class="max-sm:text-sm">
              <span>Public</span>
            </button>
            <button mat-menu-item class="max-sm:text-sm">
              <span>Anyone with the link</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <!-- copy link -->
    <div class="mb-4 w-full">
      <p class="text-gray-600 mb-2">Event Link</p>
      <div class="flex justify-between items-center w-full">
        <input
          type="text"
          class="bg-[#F4F4F4] p-2 px-4 rounded-full font-light w-[95%] text-nowrap overflow-y-scroll"
          [(ngModel)]="eventDetail.url"
          readonly
        />

        <button
          [cdkCopyToClipboard]="eventDetail.url"
          class="flex items-center justify-center active:ring-2 active:ring-green-700 rounded-xl p-2"
        >
          <mat-icon>filter_none</mat-icon>
        </button>
      </div>
      <p class="text-gray-600 mt-2">Everyone can access an event info</p>
    </div>
  </div>
  <!-- status list -->
  <div class="flex justify-between gap-4 flex-wrap mt-8">
    <ng-container *ngFor="let status of eventDetail.listStatus">
      <div class="bg-[#F4F4F4] p-2.5 text-sm w-36">
        <p class="text-gray-600">{{ status._id }}</p>
        <p class="font-semibold mt-2">{{ status.count }}</p>
      </div>
    </ng-container>
  </div>
  <!-- select table -->
  <div class="flex gap-20 mt-10 text-xl">
    <button (click)="changeType(true)">
      <h3 [ngClass]="isActive ? 'border-b-2 border-black' : 'opacity-30'">
        Participant
      </h3>
    </button>
    <button (click)="changeType(false)">
      <h3 [ngClass]="!isActive ? 'border-b-2 border-black' : 'opacity-30'">
        Leave
      </h3>
    </button>
  </div>
  <!-- Main List -->
  <div class="md:flex md:justify-between md:items-center mt-10">
    <h2 *ngIf="isActive" class="text-2xl font-semibold">
      Main List Participant
    </h2>
    <h2 *ngIf="!isActive" class="text-2xl font-semibold">
      Participant Leaving List
    </h2>

    <button
      class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
    >
      <mat-icon> vertical_align_bottom</mat-icon><span>Export list</span>
    </button>
  </div>
  <div class="flex flex-col mt-6">
    <div class="overflow-x-auto">
      <table
        class="min-w-full table-fixed border-separate border-spacing-2"
        *ngIf="!isLoading"
      >
        <thead>
          <tr class="text-left cursor-pointer">
            <th
              class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[30%]"
              (click)="sortBy('name')"
            >
              <span class="text-center">Participant name</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'name'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] font-light relative text-center"
              (click)="sortBy('status')"
            >
              <span class="text-center">payment</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'status'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <th
              class="pe-2 bg-[#F4F4F4] relative text-center font-light"
              (click)="sortBy('createdAt')"
            >
              <span class="text-center">Joining time</span>
              <mat-icon
                *ngIf="queryParams.sortBy === 'createdAt'"
                class="absolute right-0"
                >arrow_downward</mat-icon
              >
            </th>
            <!-- <th class="pe-2 bg-[#F4F4F4] font-light">
              <div class="relative text-center">
                <span class="text-center">Document</span>
              </div>
            </th> -->
            <th class="pe-2 bg-[#F4F4F4] font-light">
              <div class="relative text-center">
                <span class="text-center">Action</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="participants">
          <ng-container *ngFor="let data of participants">
            <tr
              class="bg-white border-2 shadow rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
            >
              <td class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate">
                {{ data.userId.name }}
              </td>
              <td
                class="px-6 py-4 truncate text-center"
                [ngClass]="
                  data.status === 'request_refund' ? 'text-red-500' : ''
                "
              >
                {{ data.status }}
              </td>
              <td class="px-6 py-4 truncate text-center">
                {{ data.createdAt | date : "dd/MM/yy" }}
              </td>
              <!-- <td class="px-6 py-4 truncate text-center">
                <a (click)="getParticipantDetail(data.userId._id)">
                  <span
                    class="text-[#1F639B] underline cursor-pointer"
                    *ngIf="data.status === 'approved'"
                    >View Payment Slip</span
                  >
                </a>
                <a (click)="getParticipantDetail(data.userId._id)">
                  <span
                    class="text-[#1F639B] underline cursor-pointer"
                    *ngIf="data.status === 'refunded' && data.refundedAt"
                    >View Refund Slip</span
                  >
                </a>
                <span
                  *ngIf="
                    data.status === 'request_refund' ||
                    data.status === 'pending'
                  "
                  >-</span
                >
                <span
                  class="opacity-30 underline"
                  *ngIf="data.status === 'leave' && data.refundedAt"
                  >View Refund Slip</span
                >
              </td> -->
              <td
                *ngIf="data.status === 'request_refund'"
                class="px-6 py-4 cursor-pointer rounded-tr-lg rounded-br-lg text-[#1F639B] underline truncate"
              >
                <a
                  class="flex gap-2 justify-center"
                  [routerLink]="['/admin/refund', id]"
                  [queryParams]="{ userId: data.userId._id }"
                >
                  <span>View Request</span><mat-icon>arrow_forward</mat-icon>
                </a>
              </td>
              <td
                *ngIf="data.status === 'approved' || data.status === 'pending'"
                class="px-6 py-4 cursor-pointer rounded-tr-lg rounded-br-lg truncate"
              >
                <div
                  *ngIf="data.status === 'approved'"
                  class="bg-[#389816] flex justify-center items-center gap-2 text-white w-full h-full py-1.5 px-20 rounded-full"
                >
                  <mat-icon>done</mat-icon>
                  <span>Paid</span>
                </div>
                <div *ngIf="data.status === 'pending'" class="text-center">
                  <span>-</span>
                </div>
              </td>
              <td
                *ngIf="data.status === 'refunded' || data.status === 'leave'"
                class="px-6 py-4 cursor-pointer rounded-tr-lg rounded-br-lg truncate"
              >
                <div
                  *ngIf="data.status === 'refunded' && data.refundedAt"
                  class="bg-[#F4F4F4] flex justify-center items-center w-full h-full py-1.5 px-20 rounded-full"
                >
                  <span>Refunded</span>
                </div>
                <div
                  *ngIf="data.status === 'leave' && !data.refundedAt"
                  class="bg-[#F4F4F4] flex justify-center items-center w-full h-full py-1.5 px-20 rounded-full"
                >
                  <span>No refund</span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <app-part-loading *ngIf="isLoading"></app-part-loading>
    </div>
    <div class="flex justify-center items-center mt-4">
      <div class="flex items-center">
        <mat-icon
          (click)="changePage(currentPage - 1)"
          [ngClass]="{ disabled: currentPage === 1 }"
          >chevron_left</mat-icon
        >
        <span>{{ currentPage }}/{{ totalPages }}</span>
        <mat-icon
          (click)="changePage(currentPage + 1)"
          [ngClass]="{ disabled: currentPage === totalPages }"
          >chevron_right</mat-icon
        >
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="isFullPageLoading"></app-loading>
