import { Component, OnInit } from '@angular/core';
import { RefundList } from 'src/app/model/admin.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-refund-approval',
  templateUrl: './refund-approval.component.html',
  styleUrls: ['./refund-approval.component.scss'],
})
export class RefundApprovalComponent implements OnInit {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  refundList!: RefundList[];
  isFullPageLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    sortBy: null,
    sortOrderBy: 'desc',
  };

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.getRefundList();
  }

  getRefundList() {
    const path = '/backoffice/refund/list';
    this.isFullPageLoading = true;

    this.dataService.getDataWithParams(path, this.queryParams).subscribe({
      next: (data: any) => {
        this.refundList = data.data.totalData;
        this.isFullPageLoading = false;
        console.log(data);
      },
      error: (err) => console.log(err),
      complete: () => (this.isFullPageLoading = false),
    });
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.getRefundList();
    }
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      this.queryParams.sortBy = null;
    } else {
      this.queryParams.sortBy = sortType;
    }

    this.getRefundList();
  }
}
