import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-denied-reason',
  templateUrl: './denied-reason.component.html',
  styleUrls: ['./denied-reason.component.scss'],
})
export class DeniedReasonComponent {
  reason = '';
  otherReason = '';
  errMsg = '';

  constructor(public dialogRef: MatDialogRef<DeniedReasonComponent>) {}

  onSelectCondition(text: string) {
    this.reason = text;
    this.otherReason = '';
  }

  onSubmit() {
    this.errMsg = '';

    if (this.reason.length !== 0) {
      if (this.reason !== 'Other') {
        this.dialogRef.close({ isConfirm: true, reason: this.reason });
      } else {
        if (this.otherReason.length === 0) {
          this.errMsg = 'Please fill your other reason';
        } else {
          this.dialogRef.close({ isConfirm: true, reason: this.otherReason });
        }
      }
    } else {
      this.errMsg = 'Please the select reason of denied refund';
    }
  }
}
