export enum EventStatusEnum {
  open = 'open',
  closed = 'closed',
  cancelled = 'cancelled',
  ended = 'ended',
}

export enum PaymentConditionEnum {
  advance = 'ADVANCE',
  upon = 'UPON',
  without = 'WITHOUT',
  free = 'FREE',
}
