import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeCardNumber',
})
export class ChangeCardNumberPipe implements PipeTransform {
  transform(cardNumber: string): unknown {
    const censerCard = cardNumber.slice(8, 12);

    const newCardNumber = `***${censerCard}`;

    return newCardNumber;
  }
}
