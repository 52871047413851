<div class="container p-5">
  <h1 class="text-base font-medium">Please fill the reason of denied refund</h1>
  <div class="my-6 flex flex-col gap-2">
    <div class="flex items-center gap-2">
      <div
        (click)="onSelectCondition('Transaction incorrect')"
        class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
      >
        <div
          class="z-10 rounded-full w-full h-full"
          [ngClass]="reason === 'Transaction incorrect' ? ' bg-black' : ''"
        ></div>
      </div>
      <p>Transaction incorrect</p>
    </div>
    <div class="flex items-center gap-2">
      <div
        (click)="onSelectCondition('No refund policy')"
        class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
      >
        <div
          class="z-10 rounded-full w-full h-full"
          [ngClass]="reason === 'No refund policy' ? ' bg-black' : ''"
        ></div>
      </div>
      <p>No refund policy</p>
    </div>
    <div class="flex items-center gap-2">
      <div
        (click)="onSelectCondition('Other')"
        class="border z-0 border-black rounded-full w-9 h-9 p-0.5 cursor-pointer"
      >
        <div
          class="z-10 rounded-full w-full h-full"
          [ngClass]="reason === 'Other' ? ' bg-black' : ''"
        ></div>
      </div>
      <p>Other...</p>
    </div>
    <textarea
      *ngIf="reason === 'Other'"
      id="message"
      rows="4"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Please fill the reason..."
      [(ngModel)]="otherReason"
    ></textarea>
    <span *ngIf="errMsg" class="text-red-500 text-sm font-light">{{
      errMsg
    }}</span>
  </div>

  <div
    class="grid grid-cols-1 mt-10 w-full gap-4 sm:flex sm:items-center sm:justify-between"
  >
    <button
      (click)="onSubmit()"
      class="w-full transition ease-in-out delay-150 hover:scale-110 duration-150 bg-red-500 border-2 hover:border-red-500 hover:bg-red-500 border-red-500 text-white hover:text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
    >
      <span>Confirm Reject</span>
    </button>
    <button
      [mat-dialog-close]="false"
      class="w-full transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
    >
      <span>Back</span>
    </button>
  </div>
</div>
