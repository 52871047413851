import { Component } from '@angular/core';

@Component({
  selector: 'app-part-loading',
  templateUrl: './part-loading.component.html',
  styleUrls: ['./part-loading.component.scss']
})
export class PartLoadingComponent {

}
