<!-- login.component.html -->
<div class="flex items-center justify-center min-h-screen bg-gray-200">
  <div class="max-w-md w-full bg-white rounded-lg shadow-md p-6">
    <div class="mb-4">
      <h3 class="text-center text-2xl font-semibold">VISA LIFF</h3>
      <p class="text-center text-sm text-gray-600">(Admin system)</p>
    </div>
    <div class="mb-8">
      <h1 class="text-xl font-semibold">Login</h1>
    </div>
    <form (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm">
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700"
          >Email</label
        >
        <input
          type="email"
          id="email"
          ngModel
          name="email"
          required
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Email"
        />
      </div>
      <div class="mb-6">
        <label for="password" class="block text-sm font-medium text-gray-700"
          >Password</label
        >
        <input
          type="password"
          id="password"
          ngModel
          name="password"
          required
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Password"
        />
      </div>
      <div>
        <button
          type="submit"
          [disabled]="!loginForm.valid"
          class="w-full bg-black text-white rounded-md py-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</div>
