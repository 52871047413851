<div class="container p-5">
  <div class="flex flex-col items-center">
    <div class="my-8">
      <mat-icon class="transform scale-[4]">error_outline</mat-icon>
    </div>
    <h1 class="text-xl font-medium tracking-wide text-center">
      Are you sure to discard change?
    </h1>
    <p class="text-sm font-light text-[#7C7C7C] mt-2.5">
      Every edited information will not been saved
    </p>
    <div
      class="grid grid-cols-1 mt-10 w-full gap-4 sm:flex sm:items-center sm:justify-between"
    >
      <button
        [mat-dialog-close]="true"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-[#13222C] hover:bg-yellow-400 hover:text-black text-white rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Discard Change</span>
      </button>
      <button
        [mat-dialog-close]="false"
        class="transition ease-in-out delay-150 hover:scale-110 duration-150 bg-white border-2 hover:border-yellow-400 hover:bg-yellow-400 border-black text-black rounded-full flex justify-center gap-2 items-center px-10 py-2.5"
      >
        <span>Keep Staying</span>
      </button>
    </div>
  </div>
</div>
