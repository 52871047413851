<nav class="fixed top-0 z-50 w-full bg-[#13222C] border-b border-gray-200">
  <div class="px-3 py-3 lg:px-5 lg:pl-3">
    <div class="flex items-center justify-between pe-[5%]">
      <!-- logo and sidebar dropdown mobile -->
      <div class="flex items-center justify-start rtl:justify-end">
        <div mat-raised-button (click)="drawer.toggle()">
          <button
            type="button"
            class="inline-flex items-center p-2 text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 me-2"
          >
            <mat-icon>view_headline</mat-icon>
          </button>
        </div>

        <!-- logo -->
        <div class="bg-white">
          <p class="text-gray-600 font-bold">VISA LIFF</p>
          <p class="text-xs text-gray-500">(Admin system)</p>
        </div>
      </div>

      <!-- noti and icon -->
      <div class="flex gap-5">
        <div mat-button [matMenuTriggerFor]="noti">
          <button
            class="bg-white flex items-center rounded-full focus:ring-4 focus:ring-gray-300 p-2"
          >
            <mat-icon>notifications_none</mat-icon>
          </button>
        </div>
        <div mat-button [matMenuTriggerFor]="iconMenu">
          <button
            type="button"
            class="bg-white rounded-full focus:ring-4 focus:ring-gray-300 p-2"
          >
            <div class="flex items-center">
              <mat-icon class="text-black mr-2">account_circle</mat-icon>
              <span>Admin</span>
              <span class="ms-4 max-sm:hidden flex items-center justify-center">
                <mat-icon>expand_more</mat-icon></span
              >
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- dropdown for icon -->
<mat-menu #iconMenu="matMenu" xPosition="before" class="w-64">
  <button mat-menu-item>Merchant Profile</button>
  <button mat-menu-item>Income Setting</button>
  <button (click)="isLogout()" mat-menu-item style="color: red">Logout</button>
</mat-menu>
<!--dropdown for noti -->
<mat-menu #noti="matMenu" xPosition="before">
  <button mat-menu-item>Item 1</button>
  <button mat-menu-item>Item 2</button>
</mat-menu>

<!-- side nav -->
<aside
  class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-[#F4F4F4] border-r border-gray-200 sm:translate-x-0"
  aria-label="Sidebar"
>
  <div class="h-full px-3 pb-4 overflow-y-auto bg-[#F4F4F4]">
    <ul class="space-y-2 font-medium">
      <li>
        <a
          routerLink="/admin/dashboard"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>dashboard</mat-icon>
          <span class="ms-3">Dashboard</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/manage-event"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>event</mat-icon>
          <span class="ml-3">Manage Event</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/refund"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>credit_card</mat-icon>
          <span class="flex-1 ms-3 whitespace-nowrap">Refund Approval</span>
          <span
            class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-white bg-[#13222C] rounded-full"
            >{{ totalCount }}</span
          >
        </a>
      </li>
      <!-- <li>
        <a
          routerLink="/admin/subscription"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon> edit_attributes</mat-icon>
          <span class="ml-3">Subscription</span>
        </a>
      </li> -->
      <!-- <li>
        <a
          (click)="isLogout()"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>logout</mat-icon>
          <span class="ml-3">Logout</span>
        </a>
      </li> -->
    </ul>
  </div>
</aside>

<!-- content -->

<mat-drawer-container hasBackdrop="unset">
  <mat-drawer #drawer mode="over" class="w-64 pt-20 bg-white"
    ><ul>
      <li>
        <a
          (click)="drawer.toggle()"
          routerLink="/admin/dashboard"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>dashboard</mat-icon>
          <span class="ms-3">Dashboard</span>
        </a>
      </li>
      <li>
        <a
          (click)="drawer.toggle()"
          routerLink="/admin/manage-event"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>event</mat-icon>
          <span class="ml-3">Manage Event</span>
        </a>
      </li>
      <li>
        <a
          (click)="drawer.toggle()"
          routerLink="/admin/refund"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon>credit_card</mat-icon>
          <span class="flex-1 ms-3 whitespace-nowrap">Refund Approval</span>
          <span
            class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-white bg-[#13222C] rounded-full"
            >{{ totalCount }}</span
          >
        </a>
      </li>
      <!-- <li>
        <a
          (click)="drawer.toggle()"
          routerLink="/admin/subscription"
          class="flex items-center p-2 text-gray-900 rounded-lg hover:bg-white group"
        >
          <mat-icon> edit_attributes</mat-icon>
          <span class="ml-3">Subscription</span>
        </a>
      </li> -->
    </ul>
  </mat-drawer>

  <div class="p-4 sm:ml-64 bg-white">
    <div class="p-4 mt-14"><router-outlet></router-outlet></div>
  </div>
  <!-- footer -->
  <footer
    class="bg-[#13222C] text-center p-4 mt-10 fixed w-[100%] bottom-0 z-20"
  >
    <div class="text-white sm:ms-64">© 2023 Copyright</div>
  </footer>
</mat-drawer-container>
