import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  EventDetail,
  ParticipantDetail,
  ParticipantsByEvent,
} from 'src/app/model/admin.model';
import { ManageEventService } from 'src/app/services/manage-event.service';
import { EventStatusEnum } from 'src/app/shared/enum/admin.enum';
import { ParticipantInfoComponent } from '../../components/participant-info/participant-info.component';
import { CloseEventPopupComponent } from '../../components/popup/close-event-popup/close-event-popup.component';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
  id!: string;
  eventDetail!: EventDetail;
  isActive = true;
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  participants!: ParticipantsByEvent[];
  isFullPageLoading = false;
  isLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    type: 'participant',
    sortBy: null,
    sortOrderBy: 'desc',
  };

  constructor(
    private route: ActivatedRoute,
    private manageEventService: ManageEventService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getEventDetail();
    this.getAllParticipants();
  }

  get status() {
    switch (this.eventDetail.status) {
      case EventStatusEnum.open:
        return 'Register Open';
      case EventStatusEnum.closed:
        return 'Register Close';
      case EventStatusEnum.cancelled:
        return 'Event Cancelled';
      case EventStatusEnum.ended:
        return 'Event Ended';
      default:
        return '';
    }
  }

  getEventDetail() {
    this.isFullPageLoading = true;

    this.manageEventService.getEventById(this.id).subscribe({
      next: (data: any) => {
        this.eventDetail = data.data;
        this.isFullPageLoading = false;
        console.log(this.eventDetail);
      },
      error: (err) => console.log(err),
      complete: () => {
        this.isFullPageLoading = false;
      },
    });
  }

  getAllParticipants() {
    this.isLoading = true;
    this.manageEventService
      .getParticipantByEventId(this.id, this.queryParams)
      .subscribe({
        next: (data: any) => {
          this.participants = data.data.totalData;
          this.isLoading = false;
          console.log(data);
        },
        error: (err) => console.log(err),
        complete: () => (this.isLoading = false),
      });
  }

  // getParticipantDetail(userId: string) {
  //   this.manageEventService
  //     .getParticipantByUserId(this.id, userId)
  //     .subscribe((data) => {
  //       const participantData = { ...data.data };

  //       this.openParticipantInfo(participantData);
  //     });
  // }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      this.queryParams.sortBy = null;
    } else {
      this.queryParams.sortBy = sortType;
    }

    this.getAllParticipants();
  }

  changeType(active: boolean) {
    if (active !== this.isActive) {
      this.isActive = active;
      if (this.isActive) {
        this.queryParams.type = 'participant';
      } else {
        this.queryParams.type = 'leave';
      }
      this.getAllParticipants();
    }
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.getAllParticipants();
    }
  }

  // openParticipantInfo(data: ParticipantDetail) {
  //   const dialogRef = this.matDialog.open(ParticipantInfoComponent, {
  //     enterAnimationDuration: '100ms',
  //     exitAnimationDuration: '300ms',
  //     data: data,
  //     width: '350px',
  //     height: '500px',
  //   });
  // }

  openClosePermission(type: string) {
    const dialogRef = this.matDialog.open(CloseEventPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      disableClose: true,
      data: { type, id: this.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getEventDetail();
      }
    });
  }
}
