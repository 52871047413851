import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EventDetail, EventForm } from 'src/app/model/admin.model';
import { CanComponentDeactivate } from 'src/app/services/can-deactivate.guard';
import { ManageEventService } from 'src/app/services/manage-event.service';
import { DiscardPopupComponent } from '../../components/popup/discard-popup/discard-popup.component';
import { SuccessPopupComponent } from '../../components/popup/success-popup/success-popup.component';

@Component({
  selector: 'app-edit-event-page',
  templateUrl: './edit-event-page.component.html',
  styleUrls: ['./edit-event-page.component.scss'],
})
export class EditEventPageComponent implements OnInit, CanComponentDeactivate {
  id!: string;
  createForm!: FormGroup;
  isSeperate = false;
  isFixed = false;
  isAdjust = false;
  isAutoApprove = false;
  isLoading = false;
  errMsg = '';
  isFullPageLoading = false;
  eventDetail!: EventDetail;
  isSuccess = false;

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private manageEventService: ManageEventService,
    public matDialog: MatDialog,
    private location: Location
  ) {}

  get pricePerparticipant() {
    const price = this.createForm.get('price')?.value;
    const praticipantNumber = this.createForm.get('praticipantNumber')?.value;

    if (price) {
      if (this.isSeperate) {
        if (this.isFixed) {
          return Math.ceil(price / praticipantNumber);
        } else if (this.isAdjust) {
          return '(Depending on parcitipant number)';
        } else {
          return '(Please select sperate option)';
        }
      } else {
        return price;
      }
    } else {
      return 0;
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getEventDetail();
    this.initForm();
  }

  getEventDetail() {
    this.isFullPageLoading = true;

    this.manageEventService.getEventById(this.id).subscribe({
      next: (data: any) => {
        this.eventDetail = data.data;
        console.log(this.eventDetail);
      },
      error: (err) => console.log(err),
      complete: () => {
        this.patchValue();
      },
    });
  }

  initForm() {
    this.createForm = this.fb.group({
      eventName: [null, Validators.required],
      date: [null, Validators.required],
      timeStart: [null, Validators.required],
      timeEnd: [null, Validators.required],
      locationName: [null, Validators.required],
      praticipantNumber: [1, Validators.required],
      paymentCondition: [null, Validators.required],
      price: [null, Validators.required],
      isRefund: [false, Validators.required],
      waitingHrs: '',
      waitingMins: '',
      timeWaitingEnd: [null, Validators.required],
      checkoutHrs: '',
      checkoutMins: '',
      timeCheckout: [null, Validators.required],
      closeHrs: '',
      closeMins: '',
      timeClose: [null, Validators.required],
    });
  }

  patchValue() {
    const originalDate = this.eventDetail.dateStart.toString();
    const formattedDate = originalDate.slice(0, 10);
    const patchValue = {
      eventName: this.eventDetail.eventName,
      date: formattedDate,
      timeStart: this.eventDetail.timeStart,
      timeEnd: this.eventDetail.timeEnd,
      locationName: this.eventDetail.locationName,
      praticipantNumber: this.eventDetail.praticipantNumber,
      // paymentCondition: this.eventDetail.,
      price: this.eventDetail.price,
      isRefund: this.eventDetail.isRefund,
      waitingHrs: this.eventDetail.timeWaitingEnd.slice(0, 2),
      waitingMins: this.eventDetail.timeWaitingEnd.slice(3, 5),
      timeWaitingEnd: this.eventDetail.timeWaitingEnd,
      checkoutHrs: this.eventDetail.timeCheckout.slice(0, 2),
      checkoutMins: this.eventDetail.timeCheckout.slice(3, 5),
      timeCheckout: this.eventDetail.timeCheckout,
      // closeHrs: '',
      // closeMins: '',
      // timeClose: [null, Validators.required],
    };
    this.createForm.patchValue(patchValue);
    this.isFullPageLoading = false;
  }

  selectCondition(value: string) {
    const condition = this.createForm.get('paymentCondition')?.value;
    console.log(condition);

    if (condition !== value) {
      // waiting for fix be
      this.isFixed = false;
      this.isAdjust = false;
      this.isSeperate = false;
      this.createForm.get('paymentCondition')?.setValue(value);
      if (value === 'FREE') {
        this.createForm.get('price')?.setValue(0);
      } else {
        this.createForm.get('price')?.reset();
      }
    }
  }

  selectSeperatePayment(event: boolean) {
    this.isFixed = event;
    this.isAdjust = !event;
  }

  isInputError(key: string) {
    return (
      this.createForm.get(key)?.touched && this.createForm.get(key)?.invalid
    );
  }

  increaseParticipant() {
    let value = this.createForm.get('praticipantNumber')?.value;
    value++;
    this.createForm.get('praticipantNumber')?.setValue(value);
  }
  decreaseParticipant() {
    let value = this.createForm.get('praticipantNumber')?.value;
    if (value > 1) {
      value--;
      this.createForm.get('praticipantNumber')?.setValue(value);
    }
  }

  changeIsRefund(event: any) {
    const isRefund = event.target.checked;
    this.createForm.get('isRefund')?.setValue(isRefund);
  }

  onTimeInput(type: string, event: any) {
    this.changeTimesFormat(event, event.target.name);

    let hrs = '00';
    let mins = '00';
    if (type === 'timeWaitingEnd') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('waitingHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('waitingMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    } else if (type === 'timeCheckout') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('checkoutHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('checkoutMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    } else if (type === 'timeClose') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('closeHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('closeMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    }
  }

  changeTimesFormat(event: any, subject: string) {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue.replace(/\D/g, ''));

    if (
      subject === 'waitingMins' ||
      subject === 'checkoutMins' ||
      subject === 'closeMins'
    ) {
      if (numericValue < 60) {
        this.createForm.get(subject)?.setValue(numericValue);
      } else {
        this.createForm.get(subject)?.setValue(0);
      }
    } else {
      this.createForm.get(subject)?.setValue(numericValue);
    }
  }

  changeTimesFormatDisplay(times: any) {
    if (times > 0 && times <= 9) {
      return `0${times}`;
    } else {
      return times;
    }
  }

  onlyNumber(event: any, subject: string) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');

    this.createForm.get(subject)?.setValue(numericValue);
  }

  formSummary(): EventForm {
    const createEvent: EventForm = {
      eventName: this.createForm.value.eventName,
      praticipantNumber: this.createForm.value.praticipantNumber,
      dateStart: this.createForm.value.date,
      dateEnd: this.createForm.value.date,
      timeStart: this.createForm.value.timeStart,
      timeEnd: this.createForm.value.timeEnd,
      locationName: this.createForm.value.locationName,
      paymentCondition: this.createForm.value.paymentCondition,
      price:
        this.createForm.value.paymentCondition === 'FREE'
          ? 0
          : this.createForm.value.price,
      timeWaitingEnd: this.createForm.value.timeWaitingEnd,
      timeCheckout: this.createForm.value.timeCheckout,
      isRefund: this.createForm.value.isRefund,
      isVisibility: true,
    };
    return createEvent;
  }

  // onSubmit() {
  //   this.isLoading = true;
  //   this.errMsg = '';

  //   if (this.createForm.invalid) {
  //     this.isLoading = false;
  //     this.createForm.markAllAsTouched();
  //     this.errMsg = 'Please complete the form!';
  //   } else {
  //     const formData = this.formSummary();
  //     this.manageEventService.createEvent(formData).subscribe({
  //       next: (data) => {
  //         console.log(data);
  //         this.isSuccess = true
  //         // this.openSuccess();
  //       },
  //       error: (err) => {
  //         this.isLoading = false;
  //         this.errMsg = err;
  //         console.log(err);
  //       },
  //       complete: () => {
  //         this.isLoading = false;
  //         this.errMsg = '';
  //       },
  //     });
  //   }
  // }

  onBack() {
    this.location.back();
  }

  openDiscardChanged() {
    const dialogRef = this.matDialog.open(DiscardPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  openSuccess() {
    const dialogRef = this.matDialog.open(SuccessPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => this.onBack());
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!this.isSuccess) {
      return this.openDiscardChanged();
    } else {
      return true;
    }
  }
}
