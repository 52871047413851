import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  showMobileSideNav = false;
  totalCount = 0;
  queryParams: any = {
    skip: 1,
    limit: 10,
    search: null,
    sortBy: null,
    sortOrderBy: 'desc',
  };

  constructor(
    private authService: AuthService,
    private dataService: DataService
  ) {}
  isLogout() {
    this.authService.logout();
  }

  getRefundCount() {
    this.dataService.getDashboard().subscribe((data: any) => {
      this.totalCount = data.data.countRefund;
      console.log(this.totalCount);
    });
  }

  ngOnInit(): void {
    this.getRefundCount();
  }
}
