import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ParticipantDetail } from 'src/app/model/admin.model';
import { ManageEventService } from 'src/app/services/manage-event.service';
import { DeniedReasonComponent } from '../../components/denied-reason/denied-reason.component';
import { SuccessPopupComponent } from '../../components/popup/success-popup/success-popup.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-refund-request-page',
  templateUrl: './refund-request-page.component.html',
  styleUrls: ['./refund-request-page.component.scss'],
})
export class RefundRequestPageComponent implements OnInit {
  eventId!: string;
  userId!: string;
  participantDetail!: ParticipantDetail;
  currentFile!: File;
  isFileSelected = false;
  fileSelectedText = '';
  fileSelectedType = '';
  fileUrl: any;
  isFullPageLoading = false;
  isLoading = false;
  errMsg = '';
  validFileType = ['image/jpeg', 'image/png'];
  rejectReason!: string;

  constructor(
    private route: ActivatedRoute,
    private manageEventService: ManageEventService,
    public matDialog: MatDialog,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.eventId = this.route.snapshot.params['id'];

    this.userId = this.route.snapshot.queryParams['userId'];

    this.getRefundReqDetail();
  }

  getRefundReqDetail() {
    this.isFullPageLoading = true;
    this.manageEventService
      .getParticipantByUserId(this.eventId, this.userId)
      .subscribe({
        next: (data) => {
          this.participantDetail = data.data;
          this.isFullPageLoading = false;
          console.log(this.participantDetail);
        },
        error: (err) => console.log(err),
        complete: () => (this.isFullPageLoading = false),
      });
  }

  selectFile(event: any) {
    this.currentFile = event.target.files[0];

    if (this.currentFile) {
      if (this.validFileType.includes(this.currentFile.type)) {
        this.isFileSelected = true;
        this.fileSelectedText = this.currentFile.name;
        this.fileSelectedType = this.currentFile.type;
        const reader = new FileReader();
        reader.readAsDataURL(this.currentFile);
        reader.onload = (_event: any) => {
          this.fileUrl = _event.target.result;
        };
      } else {
        this.isFileSelected = false;
        alert('Please upload only slip image(jpeg,png)');
      }
    }
  }

  onDeleteSelectedFIle() {
    this.isFileSelected = false;
    this.fileSelectedText = '';
    this.fileSelectedType = '';
    this.fileUrl = '';
  }

  openDenyPopup() {
    const dialogRef = this.matDialog.open(DeniedReasonComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '450px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.isConfirm) {
        this.rejectReason = result.reason;
        this.onSubmit(false);
      }
    });
  }
  openSuccess(isRefund: boolean) {
    const dialogRef = this.matDialog.open(SuccessPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      disableClose: true,
      data: { isRefund: true, isApprove: isRefund },
    });

    dialogRef.afterClosed().subscribe((result) => this.onBack());
  }

  onBack() {
    this.location.back();
  }

  formSummary(isRefund: boolean): FormData {
    const formData = new FormData();
    formData.append('status', JSON.stringify(isRefund));
    if (isRefund) {
      formData.append('image', this.currentFile);
      formData.append('rejectReason', JSON.stringify(null));
    } else {
      formData.append('rejectReason', this.rejectReason);
    }

    return formData;
  }

  onSubmit(isRefund: boolean) {
    console.log(this.participantDetail._id);

    this.isLoading = true;
    this.errMsg = '';
    const formData = this.formSummary(isRefund);
    this.manageEventService
      .updateRefundUser(this.participantDetail._id, formData)
      .subscribe({
        next: (result) => {
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.errMsg = err;
        },
        complete: () => {
          this.isLoading = false;
          this.openSuccess(isRefund);
        },
      });
  }
}
