import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventForm } from 'src/app/model/admin.model';
import { ManageEventService } from 'src/app/services/manage-event.service';
import { PaymentConditionEnum } from 'src/app/shared/enum/admin.enum';
import { CreateSuccessComponent } from '../../components/create-success/create-success.component';
import { interval } from 'rxjs';

@Component({
  selector: 'app-create-event-page',
  templateUrl: './create-event-page.component.html',
  styleUrls: ['./create-event-page.component.scss'],
})
export class CreateEventPageComponent implements OnInit {
  createForm!: FormGroup;
  isSeperate = false;
  isFixed = false;
  isAdjust = false;
  isAutoApprove = false;
  isLoading = false;
  errMsg = '';

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private router: Router,
    private manageEventService: ManageEventService,
    public matDialog: MatDialog
  ) {}

  get pricePerparticipant() {
    const price = this.createForm.get('price')?.value;
    const praticipantNumber = this.createForm.get('praticipantNumber')?.value;

    if (price) {
      if (this.isSeperate) {
        if (this.isFixed) {
          return Math.ceil(price / praticipantNumber);
        } else if (this.isAdjust) {
          return '(Depending on parcitipant number)';
        } else {
          return '(Please select sperate option)';
        }
      } else {
        return price;
      }
    } else {
      return 0;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.createForm = this.fb.group({
      eventName: [null, Validators.required],
      date: [null, Validators.required],
      timeStart: [null, Validators.required],
      timeEnd: [null, Validators.required],
      locationName: [null, Validators.required],
      praticipantNumber: [1, Validators.required],
      paymentCondition: [null, Validators.required],
      price: [null, Validators.required],
      isRefund: [false, Validators.required],
      waitingHrs: '',
      waitingMins: '',
      timeWaitingEnd: [null, Validators.required],
      checkoutHrs: '',
      checkoutMins: '',
      timeCheckout: [null, Validators.required],
      closeHrs: '',
      closeMins: '',
      timeClose: [null, Validators.required],
    });
  }

  selectCondition(value: string) {
    const condition = this.createForm.get('paymentCondition')?.value;
    console.log(condition);

    if (condition !== value) {
      // waiting for fix be
      this.isFixed = false;
      this.isAdjust = false;
      this.isSeperate = false;
      this.createForm.get('paymentCondition')?.setValue(value);
      if (value === 'FREE') {
        this.createForm.get('price')?.setValue(0);
      } else {
        this.createForm.get('price')?.reset();
      }
    }
  }

  selectSeperatePayment(event: boolean) {
    this.isFixed = event;
    this.isAdjust = !event;
  }

  isInputError(key: string) {
    return (
      this.createForm.get(key)?.touched && this.createForm.get(key)?.invalid
    );
  }

  increaseParticipant() {
    let value = this.createForm.get('praticipantNumber')?.value;
    value++;
    this.createForm.get('praticipantNumber')?.setValue(value);
  }
  decreaseParticipant() {
    let value = this.createForm.get('praticipantNumber')?.value;
    if (value > 1) {
      value--;
      this.createForm.get('praticipantNumber')?.setValue(value);
    }
  }

  changeIsRefund(event: any) {
    const isRefund = event.target.checked;
    this.createForm.get('isRefund')?.setValue(isRefund);
  }

  onTimeInput(type: string, event: any) {
    this.changeTimesFormat(event, event.target.name);

    let hrs = '00';
    let mins = '00';
    if (type === 'timeWaitingEnd') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('waitingHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('waitingMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    } else if (type === 'timeCheckout') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('checkoutHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('checkoutMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    } else if (type === 'timeClose') {
      hrs = this.changeTimesFormatDisplay(
        this.createForm.get('closeHrs')?.value || '00'
      );
      mins = this.changeTimesFormatDisplay(
        this.createForm.get('closeMins')?.value || '00'
      );
      const waitingResult = `${hrs}:${mins}`;
      this.createForm.get(type)?.setValue(waitingResult);
    }
  }

  changeTimesFormat(event: any, subject: string) {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue.replace(/\D/g, ''));

    if (
      subject === 'waitingMins' ||
      subject === 'checkoutMins' ||
      subject === 'closeMins'
    ) {
      if (numericValue < 60) {
        this.createForm.get(subject)?.setValue(numericValue);
      } else {
        this.createForm.get(subject)?.setValue(0);
      }
    } else {
      this.createForm.get(subject)?.setValue(numericValue);
    }
  }

  changeTimesFormatDisplay(times: any) {
    if (times > 0 && times <= 9) {
      return `0${times}`;
    } else {
      return times;
    }
  }

  onlyNumber(event: any, subject: string) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');

    this.createForm.get(subject)?.setValue(numericValue);
  }

  formSummary(): EventForm {
    const createEvent: EventForm = {
      eventName: this.createForm.value.eventName,
      praticipantNumber: this.createForm.value.praticipantNumber,
      dateStart: this.createForm.value.date,
      dateEnd: this.createForm.value.date,
      timeStart: this.createForm.value.timeStart,
      timeEnd: this.createForm.value.timeEnd,
      locationName: this.createForm.value.locationName,
      paymentCondition: this.createForm.value.paymentCondition,
      price:
        this.createForm.value.paymentCondition === 'FREE'
          ? 0
          : this.createForm.value.price,
      timeWaitingEnd: this.createForm.value.timeWaitingEnd,
      timeCheckout: this.createForm.value.timeCheckout,
      isRefund: this.createForm.value.isRefund,
      isVisibility: true,
    };
    return createEvent;
  }

  openSuccessPopup(data: any) {
    const dialogRef = this.matDialog.open(CreateSuccessComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.router.navigate(['admin/manage-event']);
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.errMsg = '';

    if (this.createForm.invalid) {
      this.isLoading = false;
      this.createForm.markAllAsTouched();
      this.errMsg = 'Please complete the form!';
    } else {
      const formData = this.formSummary();
      this.manageEventService.createEvent(formData).subscribe({
        next: (data) => {
          console.log(data);
          this.openSuccessPopup(data);
        },
        error: (err) => {
          this.isLoading = false;
          this.errMsg = err;
          console.log(err);
        },
        complete: () => {
          this.isLoading = false;
          this.errMsg = '';
        },
      });
    }
  }
}
