<div
  class="container sm:mx-auto sm:ps-10 pt-10 bg-white flex flex-col"
  *ngIf="!isFullPageLoading"
>
  <h1 class="text-3xl font-semibold mb-10">Refund Approval</h1>

  <div class="overflow-x-auto" *ngIf="refundList">
    <table class="min-w-full table-auto border-separate border-spacing-2">
      <thead>
        <tr class="text-left cursor-pointer">
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('id')"
          >
            <span class="text-center">ID</span>
            <mat-icon
              *ngIf="queryParams.sortBy === 'id'"
              class="absolute right-0"
              >arrow_downward</mat-icon
            >
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('name')"
          >
            <span class="text-center">Participant name</span>
            <mat-icon
              *ngIf="queryParams.sortBy === 'name'"
              class="absolute right-0"
              >arrow_downward</mat-icon
            >
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('eventName')"
          >
            <span class="text-center">Event Name</span>
            <mat-icon
              *ngIf="queryParams.sortBy === 'eventName'"
              class="absolute right-0"
              >arrow_downward</mat-icon
            >
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('leavedAt')"
          >
            <span class="text-center">Request time</span>
            <mat-icon
              *ngIf="queryParams.sortBy === 'leavedAt'"
              class="absolute right-0"
              >arrow_downward</mat-icon
            >
          </th>
          <th class="pe-2 bg-[#F4F4F4] font-light">
            <div class="relative text-center">
              <span class="text-center">Action</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let refundData of refundList">
          <tr
            class="bg-white border-2 shadow rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
          >
            <td class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate">
              {{ refundData.userId._id }}
            </td>

            <td class="px-6 py-4 truncate">
              {{ refundData.userId.displayName }}
            </td>
            <td class="px-6 py-4 text-center truncate">
              {{ refundData.events.eventName }}
            </td>
            <td class="px-6 py-4 text-center"></td>
            <td
              class="px-6 py-4 rounded-tr-lg rounded-br-lg text-[#1F639B] underline truncate cursor-pointer"
            >
              <a
                [routerLink]="['/admin/refund', refundData.events._id]"
                [queryParams]="{ userId: refundData.userId._id }"
                class="flex gap-2 justify-center"
              >
                <span>View Request</span><mat-icon>arrow_forward</mat-icon>
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="flex justify-center items-center mt-4">
    <div class="flex items-center">
      <mat-icon
        (click)="changePage(currentPage - 1)"
        [ngClass]="{ disabled: currentPage === 1 }"
        >chevron_left</mat-icon
      >
      <span>{{ currentPage }}/{{ totalPages }}</span>
      <mat-icon
        (click)="changePage(currentPage + 1)"
        [ngClass]="{ disabled: currentPage === totalPages }"
        >chevron_right</mat-icon
      >
    </div>
  </div>
</div>

<app-loading *ngIf="isFullPageLoading"></app-loading>
