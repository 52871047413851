import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/dashboard-page/dashboard.component';

import { RootComponent } from './root/root.component';
import { MaterialModule } from '../shared/material.module';
import { ManageEventComponent } from './pages/manage-event-page/manage-event.component';
import { RefundApprovalComponent } from './pages/refund-approval-page/refund-approval.component';
import { SubscriptionComponent } from './pages/subscription-page/subscription.component';
import { ChangeCardNumberPipe } from '../shared/utils/change-card-number.pipe';
import { ManageplanPopupComponent } from './components/manageplan-popup/manageplan-popup.component';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { EventDetailComponent } from './pages/event-detail-page/event-detail.component';
import { ParticipantInfoComponent } from './components/participant-info/participant-info.component';
import { CreateEventPageComponent } from './pages/create-event-page/create-event-page.component';
import { CreateSuccessComponent } from './components/create-success/create-success.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PartLoadingComponent } from './components/part-loading/part-loading.component';
import { EditEventPageComponent } from './pages/edit-event-page/edit-event-page.component';
import { DiscardPopupComponent } from './components/popup/discard-popup/discard-popup.component';
import { SuccessPopupComponent } from './components/popup/success-popup/success-popup.component';
import { CloseEventPopupComponent } from './components/popup/close-event-popup/close-event-popup.component';
import { RefundRequestPageComponent } from './pages/refund-request-page/refund-request-page.component';
import { DeniedReasonComponent } from './components/denied-reason/denied-reason.component';

@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    RootComponent,
    ManageEventComponent,
    RefundApprovalComponent,
    SubscriptionComponent,
    ChangeCardNumberPipe,
    ManageplanPopupComponent,
    EditPaymentComponent,
    EventDetailComponent,
    ParticipantInfoComponent,
    CreateEventPageComponent,
    CreateSuccessComponent,
    LoadingComponent,
    PartLoadingComponent,
    EditEventPageComponent,
    DiscardPopupComponent,
    SuccessPopupComponent,
    CloseEventPopupComponent,
    RefundRequestPageComponent,
    DeniedReasonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule,
    MatIconModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class AdminModule {}
